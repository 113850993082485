function Sponsors() {
  return (
    <div className="container mx-auto p-6">
      <h2 className="text-3xl font-bold text-center mb-6 text-white">Sponsori</h2>

      <div className="flex flex-wrap justify-center gap-6">
        <a href="https://www.ravenolromania.ro/" className="transition-transform duration-300 ease-in-out hover:scale-105">
          <img
            src="/sponsori/RAVENOL.png"
            alt="sponsor"
            className="w-32 h-32 object-cover rounded-full border-4 border-[#FAF9F6] transition-all duration-300 ease-in-out"
            style={{ backgroundColor: '#f0f0f0' }} // Culoare de fundal pentru placeholder
          />
        </a>

        <a href="https://www.redbull.com/ro-ro" className="transition-transform duration-300 ease-in-out hover:scale-105">
        <img
          src="/sponsori/REDBULL.png"
          alt="sponsor"
          className="w-32 h-32 object-cover rounded-full border-4 border-[#FAF9F6] transition-all duration-300 ease-in-out"
          style={{ backgroundColor: '#f0f0f0' }} // Culoare de fundal pentru placeholder
        />
        </a>

        <a href="https://www.facebook.com/CipGraphics/" className="transition-transform duration-300 ease-in-out hover:scale-105">
        <img
          src="/sponsori/CIP.jpg"
          alt="sponsor"
          className="w-32 h-32 object-cover rounded-full border-4 border-[#FAF9F6] transition-all duration-300 ease-in-out"
          style={{ backgroundColor: '#f0f0f0' }} // Culoare de fundal pentru placeholder
        />
        </a>

        <a href="https://www.instagram.com/cnc3design/?hl=en" className="transition-transform duration-300 ease-in-out hover:scale-105">
        <img
          src="/sponsori/CNC.jpg"
          alt="sponsor"
          className="w-32 h-32 object-cover rounded-full border-4 border-[#FAF9F6] transition-all duration-300 ease-in-out"
          style={{ backgroundColor: '#f0f0f0' }} // Culoare de fundal pentru placeholder
        />
        </a>

        <a href="https://www.facebook.com/p/Reparatii-GSM-EvoMobile-Brasov-100064492397377/" className="transition-transform duration-300 ease-in-out hover:scale-105">
        <img
          src="/sponsori/EVOMOBILE.jpg"
          alt="sponsor"
          className="w-32 h-32 object-cover rounded-full border-4 border-[#FAF9F6] transition-all duration-300 ease-in-out"
          style={{ backgroundColor: '#f0f0f0' }} // Culoare de fundal pentru placeholder
        />
        </a>

        <a href="https://www.instagram.com/gd_detailing.bv/" className="transition-transform duration-300 ease-in-out hover:scale-105">
        <img
          src="/sponsori/GDDetailing.jpg"
          alt="sponsor"
          className="w-32 h-32 object-cover rounded-full border-4 border-[#FAF9F6] transition-all duration-300 ease-in-out"
          style={{ backgroundColor: '#f0f0f0' }} // Culoare de fundal pentru placeholder
        />
        </a>

        <a href="https://www.youtube.com/@MeritaTotiBaniiMTB" className="transition-transform duration-300 ease-in-out hover:scale-105">
        <img
          src="/sponsori/MTB.jpg"
          alt="sponsor"
          className="w-32 h-32 object-cover rounded-full border-4 border-[#FAF9F6] transition-all duration-300 ease-in-out"
          style={{ backgroundColor: '#f0f0f0' }} // Culoare de fundal pentru placeholder
        />
        </a>

        <a href="https://sum179.ro/" className="transition-transform duration-300 ease-in-out hover:scale-105">
        <img
          src="/sponsori/SUM179.jpg"
          alt="sponsor"
          className="w-32 h-32 object-cover rounded-full border-4 border-[#FAF9F6] transition-all duration-300 ease-in-out"
          style={{ backgroundColor: '#f0f0f0' }} // Culoare de fundal pentru placeholder
        />
        </a>

        <a href="https://www.instagram.com/sound.for.fun/" className="transition-transform duration-300 ease-in-out hover:scale-105">
        <img
          src="/sponsori/sund4fun.jpg"
          alt="sponsor"
          className="w-32 h-32 object-cover rounded-full border-4 border-[#FAF9F6] transition-all duration-300 ease-in-out"
          style={{ backgroundColor: '#f0f0f0' }} // Culoare de fundal pentru placeholder
        />
        </a>

        <a href="https://oxidev.ro" className="transition-transform duration-300 ease-in-out hover:scale-105">
        <img
          src="/sponsori/oxidev.svg"
          alt="sponsor"
          className="w-32 h-32 object-cover rounded-full border-4 border-[#FAF9F6] transition-all duration-300 ease-in-out"
          style={{ backgroundColor: '#000000' }} // Culoare de fundal pentru placeholder
        />
        </a>

      </div>

    </div>
  );
}

export default Sponsors;
