import { useState } from "react";
import { addInscriere } from "../services/appwriteService";

function Inscrieri() {
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [licensePlate, setLicensePlate] = useState("");
  const [brand, setBrand] = useState("");
  const [description, setDescription] = useState("");
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);

  // Functia pentru a gestiona schimbarea fișierelor
  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length > 3) {
      alert("Poti incarca maximum 3 poze.");
    } else {
      setImages(files); // Setează fișierele în stare
    }
  };

  // Functia pentru trimiterea formularului
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Trimite datele către baza de date și Appwrite pentru fișiere
      await addInscriere({
        name,
        lastName,
        email,
        phone,
        licensePlate,
        brand,
        description,
        images,
      });
      alert("Inscriere trimisa cu succes!");

      // Resetează formularul după trimitere
      setName("");
      setLastName("");
      setEmail("");
      setPhone("");
      setLicensePlate("");
      setBrand("");
      setDescription("");
      setImages([]);
    } catch (error) {
      alert("A aparut o eroare. Te rugam să încerci din nou.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-black text-white min-h-screen flex flex-col">
      {/* Header */}
      <div className="container mx-auto p-6">
        <h2 className="text-3xl font-bold text-center mb-6">Formular de Înscriere</h2>
        <form
          onSubmit={handleSubmit}
          className="max-w-2xl mx-auto space-y-6 bg-[#FAF9F6] p-8 rounded-lg shadow-lg"
        >
          {/* Nume și Prenume */}
          <div className="flex space-x-4">
            <input
              type="text"
              placeholder="Nume"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full p-3 border border-transparent bg-gray-100 text-black rounded focus:ring-0 focus:outline-none"
              required
            />
            <input
              type="text"
              placeholder="Prenume"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className="w-full p-3 border border-transparent bg-gray-100 text-black rounded focus:ring-0 focus:outline-none"
              required
            />
          </div>

          {/* Email */}
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-3 border border-transparent bg-gray-100 text-black rounded focus:ring-0 focus:outline-none"
            required
          />

          {/* Telefon */}
          <input
            type="tel"
            placeholder="Număr de telefon"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            className="w-full p-3 border border-transparent bg-gray-100 text-black rounded focus:ring-0 focus:outline-none"
            required
          />

          {/* Marca Masinii */}
          <input
            type="text"
            placeholder="Marca Masinii"
            value={brand}
            onChange={(e) => setBrand(e.target.value)}
            className="w-full p-3 border border-transparent bg-gray-100 text-black rounded focus:ring-0 focus:outline-none"
            required
          />

          {/* Nr. Înmatriculare */}
          <input
            type="text"
            placeholder="Nr. Înmatriculare"
            value={licensePlate}
            onChange={(e) => setLicensePlate(e.target.value)}
            className="w-full p-3 border border-transparent bg-gray-100 text-black rounded focus:ring-0 focus:outline-none"
            required
          />

          {/* Descriere */}
          <textarea
            placeholder="Descriere (200 cuvinte)"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-full p-3 border border-transparent bg-gray-100 text-black rounded focus:ring-0 focus:outline-none"
            maxLength="200"
            required
          />

          {/* Upload poze */}
          <input
            type="file"
            accept="image/*"
            multiple
            onChange={handleImageChange}
            className="w-full p-3 border border-transparent bg-gray-100 text-black rounded focus:ring-0 focus:outline-none cursor-pointer"
          />
          <p className="text-gray-400 mt-2">Poți încărca până la 3 imagini.</p>

          {/* Buton Submit */}
          <button
            type="submit"
            className="w-full bg-yellow-500 text-black py-3 rounded-full font-semibold hover:bg-yellow-600 transition duration-300 shadow-lg"
            disabled={loading}
          >
            {loading ? "Se trimite..." : "Trimite"}
          </button>
        </form>
      </div>
    </div>
  );
}

export default Inscrieri;
