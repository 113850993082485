function Home() {
  return (
    <div className="relative h-screen overflow-hidden bg-black">
      {/* Videoclip de background pe mobil, ascuns pe desktop */}
      <video
        autoPlay
        loop
        muted
        playsInline
        className="absolute inset-0 w-full h-full object-cover filter blur-sm brightness-75"
      >
        <source src="/img/videosite.mp4" type="video/mp4" />
      </video>

      {/* Overlay pentru conținut */}
      <div className="relative z-10 flex flex-col items-center justify-center h-full text-white px-6 text-center">
        {/* Logo-ul evenimentului */}
        <img
          src="/img/ww.png"
          alt="Wheels & Whistles Logo"
          className="w-3/4 sm:w-1/2 lg:w-1/3 xl:w-1/4 max-w-sm"
        />

        <p className="text-lg sm:text-2xl mb-8 max-w-2xl drop-shadow-md">
          Un eveniment spectaculos dedicat pasionatilor de masini !
        </p>

        {/* Buton de acțiune */}
        <a
          href="/inscrieri"
          className="bg-yellow-500 text-black py-3 px-6 rounded-full font-semibold hover:bg-yellow-600 transition duration-300 shadow-lg"
        >
          Înscrie-te acum!
        </a>
      </div>

      {/* Gradient pentru un efect de estompare în partea de jos */}
      <div className="absolute inset-x-0 bottom-0 h-32 bg-gradient-to-t from-black"></div>
    </div>
  );
}

export default Home;
