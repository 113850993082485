import { Client, Databases, Storage, ID } from "appwrite";

// Inițializare client Appwrite
const client = new Client();
client.setProject("6743c00d00179464d511");

const databases = new Databases(client);
const storage = new Storage(client);

export const addInscriere = async (data) => {
  try {
    let imageIds = [];
    let fileId;

    if (data.images && data.images.length > 0) {

      for (let image of data.images) {
        if (image instanceof File) {

          const bucketId = '6743c18400287e63ee24';
          fileId = ID.unique();
          
          const uploadedFile = await storage.createFile(
            bucketId,
            fileId,
            image,
          );

          imageIds.push(uploadedFile.$id);

          if (!fileId) {
            fileId = uploadedFile.$id;
          }

          //const viewUrl = `https://cloud.appwrite.io/v1/storage/buckets/${bucketId}/files/${uploadedFile.$id}/view?project=6743c00d00179464d511&mode=admin`;
          //console.log("URL de vizualizare:", viewUrl);
        } else {
          console.error("Fișier invalid:", image);
        }
      }
    } else {
      console.log("Nu s-au selectat fisiere.");
    }

    const documentData = {
      name: data.name,
      lastName: data.lastName,
      email: data.email,
      phone: data.phone,
      licensePlate: data.licensePlate,
      brand: data.brand,
      description: data.description,
      fileId: fileId,
      images: imageIds
    };

    const response = await databases.createDocument(
      "6743c0770020053e01fb",
      "6743c0e2000d5b56e227",
      ID.unique(),
      documentData
    );

    return response;
  } catch (error) {
    console.error("Eroare la adaugarea înscrierii:", error);
    throw error;
  }
};
