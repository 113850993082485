function Echipa() {
  return (
    <div className="container mx-auto p-6 bg-black">
      <h2 className="text-3xl font-bold text-center mb-6 text-white">Echipa</h2>
      <div className="flex flex-wrap justify-center gap-6">
        {/* Carduri cu membrii echipei */}

        <div className="bg-[#FAF9F6] p-6 rounded-lg shadow-lg transform hover:scale-105 transition duration-300 ease-in-out max-w-xs w-full sm:w-1/2 md:w-1/3 lg:w-1/4">
          <div className="mb-4 flex justify-center">
            <img 
              src="team/busu.jpeg" // Înlocuiește cu URL-ul imaginii fiecărui membru
              alt="Nume Membru 1"
              className="w-32 h-32 object-cover rounded-full border-4 border-[#FAF9F6]"
            />
          </div>
          <h3 className="text-xl font-bold text-center">Busuioc Ionut</h3>
          <p className="text-center text-gray-600">Organizator</p>
        </div>

        <div className="bg-[#FAF9F6] p-6 rounded-lg shadow-lg transform hover:scale-105 transition duration-300 ease-in-out max-w-xs w-full sm:w-1/2 md:w-1/3 lg:w-1/4">
          <div className="mb-4 flex justify-center">
            <img 
              src="team/denis.jpeg" // Înlocuiește cu URL-ul imaginii fiecărui membru
              alt="Nume Membru 2"
              className="w-32 h-32 object-cover rounded-full border-4 border-[#FAF9F6]"
            />
          </div>
          <h3 className="text-xl font-bold text-center">Denis Andrei</h3>
          <p className="text-center text-gray-600">Organizator</p>
        </div>

        <div className="bg-[#FAF9F6] p-6 rounded-lg shadow-lg transform hover:scale-105 transition duration-300 ease-in-out max-w-xs w-full sm:w-1/2 md:w-1/3 lg:w-1/4">
          <div className="mb-4 flex justify-center">
            <img 
              src="team/nicu.jpeg" // Înlocuiește cu URL-ul imaginii fiecărui membru
              alt="Nume Membru 3"
              className="w-32 h-32 object-cover rounded-full border-4 border-[#FAF9F6]"
            />
          </div>
          <h3 className="text-xl font-bold text-center">Lupoiu Nicolae</h3>
          <p className="text-center text-gray-600">Organizator</p>
        </div>

        <div className="bg-[#FAF9F6] p-6 rounded-lg shadow-lg transform hover:scale-105 transition duration-300 ease-in-out max-w-xs w-full sm:w-1/2 md:w-1/3 lg:w-1/4">
          <div className="mb-4 flex justify-center">
            <img 
              src="team/rares.jpeg" // Înlocuiește cu URL-ul imaginii fiecărui membru
              alt="Nume Membru 4"
              className="w-32 h-32 object-cover rounded-full border-4 border-[#FAF9F6]"
            />
          </div>
          <h3 className="text-xl font-bold text-center">Lupusoru Rares</h3>
          <p className="text-center text-gray-600">Organizator</p>
        </div>

        <div className="bg-[#FAF9F6] p-6 rounded-lg shadow-lg transform hover:scale-105 transition duration-300 ease-in-out max-w-xs w-full sm:w-1/2 md:w-1/3 lg:w-1/4">
          <div className="mb-4 flex justify-center">
            <img 
              src="team/oxi.jpeg" // Înlocuiește cu URL-ul imaginii fiecărui membru
              alt="Nume Membru 5"
              className="w-32 h-32 object-cover rounded-full border-4 border-[#FAF9F6]"
            />
          </div>
          <h3 className="text-xl font-bold text-center">Albu Vlad</h3>
          <p className="text-center text-gray-600">Tehnic</p>
        </div>

        <div className="bg-[#FAF9F6] p-6 rounded-lg shadow-lg transform hover:scale-105 transition duration-300 ease-in-out max-w-xs w-full sm:w-1/2 md:w-1/3 lg:w-1/4">
          <div className="mb-4 flex justify-center">
            <img 
              src="team/alex.jpeg" // Înlocuiește cu URL-ul imaginii fiecărui membru
              alt="Nume Membru 5"
              className="w-32 h-32 object-cover rounded-full border-4 border-[#FAF9F6]"
            />
          </div>
          <h3 className="text-xl font-bold text-center">Barsan Alexandru</h3>
          <p className="text-center text-gray-600">Oragnizator & DJ</p>
        </div>

      </div>
    </div>
  );
}

export default Echipa;
