import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Inscrieri from "./pages/Inscrieri";
import Galerie from "./pages/Galerie";
import Echipa from "./pages/Echipa";
import Sponsors from "./pages/Sponsors";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Navbar />
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/inscrieri" element={<Inscrieri />} />
            <Route path="/galerie" element={<Galerie />} />
            <Route path="/echipa" element={<Echipa />} />
            <Route path="/sponsors" element={<Sponsors />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
