import { useState } from "react";
import { Link } from "react-router-dom";

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <nav className="bg-black text-white p-4">
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo-ul evenimentului */}
        <Link to="/">
          <img
            src="/img/bvmostwanted.png"
            alt="Wheels & Whistles Logo"
            className="h-10 sm:h-12 lg:h-14"
          />
        </Link>

        {/* Icon Hamburger */}
        <button
          className="block sm:hidden text-white focus:outline-none"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        </button>

        {/* Link-uri desktop */}
        <div className="hidden sm:flex space-x-4">
          <Link to="/" className="hover:text-yellow-500">
            Acasa
          </Link>
          <Link to="/inscrieri" className="hover:text-yellow-500">
            Inscrieri
          </Link>
          <Link to="/galerie" className="hover:text-yellow-500">
            Galerie
          </Link>
          <Link to="/echipa" className="hover:text-yellow-500">
            Echipa
          </Link>
          <Link to="/sponsors" className="hover:text-yellow-500">
            Sponsori
          </Link>
        </div>
      </div>

      {/* Meniu pentru mobil */}
      {menuOpen && (
        <div className="sm:hidden bg-black text-white p-4">
          <Link
            to="/"
            className="block py-2 hover:text-yellow-500"
            onClick={() => setMenuOpen(false)}
          >
            Acasă
          </Link>
          <Link
            to="/inscrieri"
            className="block py-2 hover:text-yellow-500"
            onClick={() => setMenuOpen(false)}
          >
            Înscrieri
          </Link>
          <Link
            to="/galerie"
            className="block py-2 hover:text-yellow-500"
            onClick={() => setMenuOpen(false)}
          >
            Galerie
          </Link>
          <Link
            to="/echipa"
            className="block py-2 hover:text-yellow-500"
            onClick={() => setMenuOpen(false)}
          >
            Echipa
          </Link>
          <Link
            to="/sponsors"
            className="block py-2 hover:text-yellow-500"
            onClick={() => setMenuOpen(false)}
          >
            Sponsori
          </Link>
        </div>
      )}
    </nav>
  );
}

export default Navbar;
